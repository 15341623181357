import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { DotSpinLoading } from '@eargo/eargo-components'

import { checkLeadIdforDocumentUpload, DATE_FORMAT, SUPPORT_CONTENT, newGuidesLinks, addressUrl, warrantyInfo } from '../../../constant'
import DashboardBanner from './DashboardBanner'

import SupportSection from './support'
import EmptyOrder from './EmptyOrder'
import VideoSection from '../SupportSection/DeviceVideoContent'
import { getRecentOrderInfo, ResetRecentOrderData } from '../../../actions/recent_order_action'
import { convertStringToAmount, getSkuImage, formatDate } from '../../../common/helpers'
import './index.scss'
import '../SupportSection/index.scss'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { clearSupportDetails, getProductDetails, getSupportCaseAccountInfo } from '../../../actions/support'
import BoxV2 from '../../common/BoxV2'

import { fetchAlluserDocuments } from '../../../actions/documents'
import { checkLeadId } from '../../../util/document'
import { fetchUserDeviceOrders, fetchWarrantyInfo } from '../../../util/orders.js'
import OrientationBanner from './OrientationBanner'

import EargoCare from './EargoCare'

const Dashboard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const isAllowed = useSelector((state) => state.support.isAllowed)
  const orders = useSelector(state => state.recent_order.recent_order)
  const displayPrice = orders?.order_channel === 'Eargo'
  const documentsList = useSelector(state => state.documents?.totalDocuments)
  const [leadId, setLeadId] = useState(false)
  const [latestDeviceProduct, setlatestDeviceProduct] = useState('')
  const [eargoCareEligibleOrders, setEargoCareEligibleOrders] = useState([])
  const [showReceiptBanner, setShowReceiptBanner] = useState(false)

  useEffect(() => {
    const fetchDocuments = () => {
      dispatch(fetchAlluserDocuments())
    }
    fetchDocuments()
  }, [])
  const [isLoading] = useSelector((state) => [
    state.support.isLoading
  ])

  const [productsName, setProductName] = useState([])
  const checkedProductsName = []
  const setOtherCheckedProducts = (Item) => {
    checkedProductsName.push(Item.product_class.toUpperCase().replace(/_/g, ' '))
  }

  const videoContent = SUPPORT_CONTENT.filter(Item => {
    if (productsName.includes(Item.product_class)) {
      setOtherCheckedProducts(Item)
      Item.content[0].info = Item.content[0].info.slice(0, 3)
    } else {
      return false
    }
    return Item
  })

  const lineItemArray = useSelector(state => state.support.lineItems)
  useEffect(() => {
    if (lineItemArray.length) {
      let productNameList = []
      lineItemArray.forEach((item) => {
        if (typeof item === 'string' && item?.includes('eargo_7')) {
          productNameList = ['eargo_7']
        } else if (productNameList.indexOf(item.product_name.toLowerCase().replace(/ /g, '_')) === -1) {
          item.product_name ? setlatestDeviceProduct(item.product_name) : setlatestDeviceProduct('')
          productNameList = [...productNameList, item.product_name.toLowerCase().replace(/ /g, '_')]
        }
      })
      setProductName(productNameList)
    }
  }, [lineItemArray])
  useEffect(() => {
    dispatch(getProductDetails())
    dispatch(getSupportCaseAccountInfo())
    dispatch(getRecentOrderInfo())
    return () => {
      dispatch(clearSupportDetails())
      dispatch(ResetRecentOrderData())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }, 2000)
    }
  }, [])

  useEffect(() => {
    async function checkLeadData () {
      try {
        const response = await checkLeadId()
        if (response && response.data && response.data.LeadSource === 'Insurance Interest') {
          const data = checkLeadIdforDocumentUpload(response)
          const responsedata = data.filter((value) => value.title === 'Documents' && value)
          setLeadId(responsedata[0].show)
        }
      } catch (error) {
        console.log('Error in check lead', error)
      }
    }
    checkLeadData()
  }, [])

  const getEargoCareEligibleOrders = async () => {
    const response = await fetchUserDeviceOrders()

    if (response?.orders?.length) {
      const orderData = response.orders.map(order => {
        const getWarrantyInfo = warrantyInfo.find(warranty => warranty.sku === order.sku)
        return {
          ...order,
          warrantyDetails: getWarrantyInfo ? getWarrantyInfo.warrantyDetails : null,
          ...(order.trialExpiresOn && { formattedDate: formatDate(order.trialExpiresOn) })
        }
      })
      setEargoCareEligibleOrders(orderData)
    }
  }

  const getWarrantyBannerInfo = async () => {
    const response = await fetchWarrantyInfo()

    if (response?.orders?.length) {
      setShowReceiptBanner(true)
    }
  }

  useEffect(() => {
    getEargoCareEligibleOrders()
  }, [])

  useEffect(() => {
    getWarrantyBannerInfo()
  }, [])

  const showFullOrder = () => {
    const newHeight = document.querySelector('.recent_order').scrollHeight + 'px'
    document.querySelector('.recent_order').style.height = newHeight
    document.querySelector('.full_order_button').style.display = 'none'
    document.querySelector('.past_orders_link').style.position = 'unset'
    document.querySelector('.past_orders_link').style.marginTop = '1rem'
  }

  const changeUrl = (value) => {
    window.location.href = window.location.href.replace('/dashboard', '') + '#' + value
  }

  const findGuidesMapUrl = (product) => {
    product = product.toLowerCase().replace(/ /g, '_')
    return newGuidesLinks[product] ? `${addressUrl}${newGuidesLinks[product]}` : `${addressUrl}guides`
  }
  return (
    <div className='dashboard_container'>
      <div className='child_container'>
      <h3 className='typographyV2 welcome_text'>Welcome, {user && user.firstName ? user.firstName : ''}</h3>
      {/* Orientation banner */}
        <OrientationBanner />
      {/* Upload purchase Receipt banner */}
        { showReceiptBanner &&
          <DashboardBanner icon='documents' action='link' link='/my-info/documents' copy={'Upload your receipt to activate your warranty,'} buttonLabel='Upload receipt'/>
      }
      {/* Recent orders guides banner */}
      {latestDeviceProduct &&
          <DashboardBanner icon='documents' action='link' link= {findGuidesMapUrl(latestDeviceProduct)} copy={`Need help getting started with your new ${latestDeviceProduct} device?`} buttonLabel='View guides'/>
      }

      {(isLoading) && <DotSpinLoading />}
      {/* Documents upload banner */}
      {leadId && documentsList && documentsList.length > 0 &&
      <DashboardBanner bannerType='info' action='link' link='/my-info/documents' icon='documents' copy={documentsList?.length > 1 ? `You have ${documentsList.length} documents that need to be uploaded` : 'You have 1 document that needs to be uploaded'} buttonLabel='View tasks'/>
      }
      {(!isLoading) && <><div className='first_row'>
        <BoxV2 isClass={false} className='dashboard_sub_container RoundedCorner'>
            <h5 className='typographyV2 dashboard_box_header'>Account Info</h5>
            <div className='name_container'>
                <span className='typographyV2 body avatar'>{user && user.firstName ? (user.firstName ? user.firstName.charAt(0) : '') + (user.lastName ? user.lastName.charAt(0) : '') : ''}</span>
                <span className='typographyV2 body_bold'>{`${(user && user.firstName) || 'N/A'} ${(user && user.lastName) || ''}`}</span>
            </div>
            <div className='contact_heading'>
                <span className='typographyV2 body_bold'>Contact details <img src='https://assets.eargo.com/account/Vector.svg' /></span>
            </div>
        <span className='typographyV2 body number dark_grey'>{(user && user.phone) || 'N/A'}</span>
        <span className='typographyV2 body email dark_grey'>{(user && user.email) || 'N/A'}</span>
        <div className='links_Container'>
            <span className='typographyV2 body_bold link' onClick={() => changeUrl('address')}>Address</span>
            <span className='typographyV2 body_bold span_middle link' onClick={() => changeUrl('payment')}>Payment</span>
            <span className='typographyV2 body_bold link' onClick={() => changeUrl('eargo-my-account-container')}>Login Info</span>
        </div>
        </BoxV2>
        <BoxV2 isClass={false} className='dashboard_sub_container RoundedCorner recent_order_container' >
        {!!orders && Object.keys(orders).length > 1 && <>
            <div className='recent_order'>
                <h5 className='typographyV2 dashboard_box_header'>Recent Orders</h5>
                <div className='number_date_container'>
                  <span className={`typographyV2 ${window.innerWidth < 600 ? 'body' : 'body_bold'} order_style`}>Order #{orders.number}</span>
                  <span className={`typographyV2 ${window.innerWidth < 600 ? 'body_bold' : 'body'} date`}>{moment(orders.createdAt).format(DATE_FORMAT)}</span>
                </div>
                {orders.lineItems && orders.lineItems.map((data, index) => {
                  return (
                    <div className='line_item_container' key={index}>
                    <div className='left_side'>
                    <img src={getSkuImage(data.sku)} className='order_img'/>
                    <div className='name_price_container desktop'>
                        <span className='typographyV2 body_bold'>{data.name} </span>
                        {displayPrice && (<span className='typographyV2 body_bold'>${convertStringToAmount(Math.abs(parseFloat(data.price, 10)).toFixed(2))}</span>)}
                    </div>
                    </div>
                    <div className='right_side'>
                    <div className='name_price_container mobile'>
                        <span className='typographyV2 body_bold'>{data.name} </span>
                        {displayPrice && (<span className='typographyV2 body_bold order_price'>${convertStringToAmount(Math.abs(parseFloat(data.price, 10)).toFixed(2))}</span>)}
                    </div>
                    <span className='typographyV2 body price quantity_price'>Qty: {data.quantity}{displayPrice && (<> x ${convertStringToAmount(Math.abs(parseFloat(data.price, 10)).toFixed(2))}</>)}</span>
                    </div>
                    </div>)
                })}
            </div>
            {!!orders && Object.keys(orders).length > 1 && orders.lineItems.length > 1 &&
            <span className='typographyV2 body_bold full_order_button' onClick={showFullOrder}>Full order<img src="https://assets.eargo.com/account/Vector.svg" className='vector'></img></span>
            }
            <span className='typographyV2 body_bold past_orders_link' onClick={() => { history.push('/my-info/order') }}>Past orders</span>
            </>
}
          {!!orders && Object.keys(orders).length < 1 && <EmptyOrder />}
        </BoxV2>
        </div>
        {eargoCareEligibleOrders?.length > 0 && <EargoCare orders ={eargoCareEligibleOrders} /> }
        {!isLoading && videoContent.map((deviceContent, i) => (
            <BoxV2 isClass={false} className='video_section_container' key={i}>
              <VideoSection key={i} productCount={i.toString()} deviceContent={deviceContent} Products={checkedProductsName} totalProductCount={videoContent.length.toString() - 1} />
            </BoxV2>
        )
        )}
        </>
        }
        {isAllowed && <SupportSection />}
      </div>
      </div>
  )
}

Dashboard.propTypes = {
  user: PropTypes.any
}
export default Dashboard
