import React, { useState } from 'react'
import './index.scss'
import { uploadDocuments } from '../../../../actions/auth'
import PropTypes from 'prop-types'
import PieSpinLoader from '../../../common/PieSpinLoader'

import { useDispatch, useSelector } from 'react-redux'
import { updateUserDocuments } from '../../../../actions/documents'
import SplitErrorContainer from '../../../order_checkout_forms/SplitErrorContainer'
import { EargoButtonV2 } from '@eargo/eargo-components'

const allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/gif']

export const PurchaseReceiptUpload = ({ heading, subHeading, id, documentsList }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState('')
  const uploadInProgress = useSelector(state => state.documents.uploadInProgress)
  const [date, setDate] = useState('')
  const [file, setFileData] = useState()
  const [fileError, setFileError] = useState(false)
  const [dateError, setDateError] = useState('')

  const handleFileChange = (e) => {
    setFileData(e.target.files[0])
    setFileError(false)
  }

  const onDrop = (e) => {
    e.preventDefault()
    if (uploadInProgress) {
      setError(true)
      setErrorMessage('Upload in progress, please wait')
    } else {
      setFileData(e.dataTransfer.files[0])
    }
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const handleOnClick = () => {
    setError(false)
    setErrorMessage('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    validateForm()
    if (file && date && !dateError && !fileError) {
      if (file) {
        const sizeBytes = file?.size
        if (sizeBytes >= 10485760) {
          setError(true)
          setErrorMessage('File is too large (Max 10MB)')
          return
        } else if (!allowedExtensions.includes(file?.type)) {
          setError(true)
          setErrorMessage('Unsupported file type')
          return
        } else {
          setError(false)
          setErrorMessage('')
        }
        const totalSize = Math.round((sizeBytes) / 1000, 1)
        setDownloadProgress('0kb / ' + totalSize + 'kb')
        setLoading(true)
        const info = new FormData()
        info.set('image', file)
        info.set('type', id)
        info.set('purchaseDate', formatDate(date))
        const res = await uploadDocuments(info, setDownloadProgress, dispatch)
        setLoading(false)
        if (res.success) {
          setUploaded(true)
          setTimeout(() => {
            dispatch(updateUserDocuments(documentsList, id))
            setUploaded(false)
          }, 2000)
        } else {
          setError(true)
          if (res.error) {
            setErrorMessage(res.error)
          } else {
            setErrorMessage('Oops! Something went wrong. Please refresh the page and try uploading your document again.')
          }
        }
      }
    }
  }

  const validateForm = () => {
    setFileError(!file)
    setDateError(validateDate(date))
  }
  const handleFocus = () => {
    setDateError('')
  }
  const handleDateChange = (event) => {
    setDate(event.target.value)
  }

  const handleInput = (event) => {
    setDate(event.target.value)
  }
  const validateDate = (dateString) => {
    if (!dateString) return 'Purchase date is required'

    const [year, month, day] = dateString.split('-').map(Number) // Check if date is valid
    const date = new Date(year, month - 1, day)

    if (date > new Date()) { // Check if date is in the future
      return 'Date can\'t be in the future'
    }
    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return 'Invalid Date'
    }
    return ''
  }

  const formatDate = (date) => {
    const [year, month, day] = date.split('-')
    return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`
  }

  const showForm = () => {
    if (loading) {
      return (
        <div className='loader_container'>
          <PieSpinLoader />
          <div className='image_name_holder interFontRegular'>{file?.name}</div>
          <div className='image_size_holder interFontRegular'>{downloadProgress}</div>
        </div>
      )
    } else if (uploaded) {
      return (
        <div>
          <img src='https://assets.eargo.com/account/insuranceform/success.png' className='success_image' />
          <div className='image_name_holder interFontRegular'>{file?.name}</div>
          <div className='image_size_holder interFontRegular'>{downloadProgress}</div>
        </div>
      )
    }
    return (
      <form className='form-container' onSubmit={handleSubmit}>
      <div className ='file-upload-section'>
        <label
          htmlFor='file-upload'
          className='file-upload'
          onDragEnter={onDragOver} onDrop={onDrop} onDragLeave={onDragOver} onDragOver={onDragOver}
        >
          <input
            id='file-upload'
            type='file'
            className='hidden-input'
            accept="image/png, image/jpg, image/jpeg, application/pdf, image/gif"
            onChange={handleFileChange}
          />
          <img src='https://assets.eargo.com/account/insuranceform/Upload_Icon.png' className='upload-icon' />
          <div className='upload-description'>Drag and Drop Files</div>
        </label>
        {file && <p className='file-name typeV2-body'>{file.name}</p>}
        {fileError && <p className='error-message typeV2-body-small'>Please upload a file</p>}
      </div>
      <div className='date-section'>
        <div className='date-container'>
        <label
          className={`primary-input-label ${dateError ? 'error_border' : ''}`}>
          <h1 className='typographyV2 floating_label'>Purchase Date</h1>
          <input
            name="Purchase date"
            className='typographyV2 primary-input-field body1_regular'
            onChange={handleDateChange}
            type={'date'}
            value= {date}
            onFocus={handleFocus}
            onBlur={validateForm}
            onInput={handleInput}
          />
        </label>
          {dateError && <p className='error-message typeV2-body-small'>{dateError}</p>}
        </div>
        <EargoButtonV2
          label="Submit"
          type="button"
          disabled={uploadInProgress}
          className='primary small interFontBold'
          onClick={handleSubmit}
          />
      </div>
    </form>
    )
  }

  return (
      <div className='purchase-receipt-container'>
        { error && <div className='sizeError'>
            <SplitErrorContainer errHeader={errorMessage} isError={false} uploadError= {true} handleClick = {handleOnClick}/>
          </div>
        }
        <div className= 'receipt-box-container' >
          <div className= {`full_width ${error && 'box_blur'}`}>
            <div className = 'title-section'>
              <div className='header'>{heading}</div>
              <div className='sub-header'>{subHeading}</div>
            </div>
            <div className='document-upload-section'>
              {showForm()}
            </div>
          </div>
        </div>
      </div>
  )
}

PurchaseReceiptUpload.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  id: PropTypes.string,
  documentsList: PropTypes.array
}
