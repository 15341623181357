import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DotSpinLoading } from '@eargo/eargo-components'
import { downloadDocument } from '../../../../util/orders'
import './index.scss'
function DocumentRowCard ({ heading, docName, uploadDate, id, type, purchaseDate }) {
  const extension_main = (docName || '').split('.')
  const extension = extension_main.length ? extension_main[extension_main.length - 1] : ''
  const date = new Date(uploadDate)
  const day = date.toLocaleString('default', { day: '2-digit' })
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.toLocaleString('default', { year: 'numeric' })

  const date_str = month + ' ' + day + ', ' + year

  const [loading, setLoading] = useState(false)

  const downloadDocuments = async () => {
    setLoading(true)
    try {
      const res = await downloadDocument(type)
      if (res) {
        let file = ''
        if (extension?.toLowerCase() === 'pdf') {
          file = new Blob([res], { type: 'application/pdf' })
        } else {
          file = new Blob([res], { type: `image/${extension?.toLowerCase()}` })
        }
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, '_blank')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('Download Document Failed', error)
    }
  }

  return (
    <>
     {(loading) && <DotSpinLoading />}
      <div className='doc_row_container'>
        <div className='normal_view'>
          <div className='left_section'>
            <div className='extension'>{extension}</div>
            <div className='file_details_container'>
              <div className='heading'>{heading}</div>
              <div className='filename'>{docName}</div>
              { purchaseDate && <div className='typeV2-body'>Purchase date: {purchaseDate}</div> }
            </div>
          </div>
          <div className='right_section'>
            <div className='date'>{date_str}</div>
            <div className='download' onClick={() => { downloadDocuments() } } >Download</div>
          </div>
        </div>
        <div className='mobile_view'>
          <div className='extension'>{extension}</div>
            <div className='file_details_container'>
              <div className='heading'>{heading}</div>
              <div className='date'>{date_str}</div>
              <div className='filename'>{docName}</div>
              { purchaseDate && <div className='typeV2-body'>Purchase date: {purchaseDate}</div> }
              <div className='download_delete_container'>
                <div className='download' onClick={() => { downloadDocuments() } } >Download</div>
              </div>
            </div>
        </div>
      </div>

      <div className='seperator'></div>
    </>
  )
}

DocumentRowCard.propTypes = {
  heading: PropTypes.string,
  docName: PropTypes.string,
  uploadDate: PropTypes.string,
  id: PropTypes.any,
  type: PropTypes.any,
  purchaseDate: PropTypes.string
}

export default DocumentRowCard
